div.ModalContainer{
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div.Header{
        /* border: 1px solid blue; */
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
    }

    div.CartProducts{
        /* border: 1px solid green; */
        margin: auto;
        width: 90%; 
        max-width: 550px;
        height: 80%;
        overflow: auto;
        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        div.ItemContainer{
            /* border: 4px solid red; */
            /*width: 10rem;*/
            /* height: 15rem; */
            flex: 0 1 50%;
            cursor: pointer;
            padding-left: 1rem;
            padding-right: 1rem;
            max-width: 250px;

            div.ItemImage{
                /* border: 4px solid purple; */
                margin: auto;
                /*max-width: 50%;*/
                position: relative;
                text-align: center;

                div.PriceCircle{
                    position: absolute;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    /* flex-direction: row-reverse; */
                    top: 0;
                    /* right: -5%; */
                    border-radius: 50%;
                    background-color: var(--mainColor);
                    color: #FFF;
                    font-family: 'Heebo';
                    padding-top: 1.2rem;
                    height: 3rem;
                    width: 3rem;

                    div.Agorot{
                        margin-bottom: 30%;
                        font-size: .5em;
                        line-height: .0rem;
                        height: 0.8rem;
                    }

                    div.Shekels{
                        font-size: 1.3em;
                        line-height: 0.8rem;
                        height: 0.8rem;
                        
                    }
                    div.Curr{
                        font-size: 0.8em;
                        line-height: 1rem;
                        height: 0.8rem;
                    }
     
                }               

                span {
                    img {
                        max-width: 150px;
                        max-height: 100px;
                        height: 100px;
                    }
                }
            }

            div.ItemName{
                /* border: 1px solid red; */
                margin: auto;
                text-align: center;
                width: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.1rem;
                font-weight: 500;
                color: var(--mainColor);
            }
        }

        div.ItemContainer:hover{
            opacity: .8;
        }
    }

    div.NoThanksFooter{
        /* border: 1px solid green; */
        margin-top: 3rem;
        display: flex;
        justify-content: center;

        span{
            /* border: 1px solid red; */
            font-size: 1.8rem;
            color: #7d7d7d;
            text-decoration: underline;
            cursor: pointer;
        }
        span:hover{
            opacity: .7;
        }
    }
}

@media (max-width: 600px){
    div.ModalContainer{
        div.Header{
            font-size: 1.5rem;
        }
    
        div.CartProducts{
            max-width: 100%;
           
            div.ItemContainer{
                max-width: 100%;
    
                div.ItemImage{
                    /*max-width: 75%;
                    padding-right: 5%;*/

                    div.PriceCircle{
                        
                        div.Agorot{
                        }
    
                        div.Shekels{
                        }
                        div.Curr{
                        }
         
                    }
                }
    
                div.ItemName{

                }
            }
        }
    
        div.NoThanksFooter{

            span{

            }
 
        }
    }
}